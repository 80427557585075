import React, { useState, useContext, useRef, useEffect } from "react";
import useForm from "../../../../services/UseForm";
import '../../../../css/payment.css';
import '../../../../css/paymentBill.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { POSContent } from "../pos";
import { UserContext } from "../../../../App";
import logo from '../../../../img/logo/qrcafe.jpg';
import kip100000 from '../../../../img/kip/100000kip.jpg';
import kip10000 from '../../../../img/kip/10000kip.jpg';
import kip1000 from '../../../../img/kip/1000kip.jpg';
import kip20000 from '../../../../img/kip/20000kip.jpg';
import kip2000 from '../../../../img/kip/2000kip.jpg';
import kip50000 from '../../../../img/kip/50000kip.jpg';
import kip5000 from '../../../../img/kip/5000kip.jpg';
import kip500 from '../../../../img/kip/500kip.jpg';
import { useReactToPrint } from 'react-to-print';

axios.defaults.baseURL = API;

class ComponentToPrint extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                if(num >= 0 || num <= 0){
                    return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                }
            }
        }
        const billDetails = this.props.BillDetails;
        const productList = this.props.TemProductList;
        const today = Date.now();
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="PaymentBillContent">
                <div className="imgQR">
                  <img className="logo" src={logo} />
                </div>
                <div className="BillHeader">
                    {
                       billDetails.LocationName !=="BIC" && 
                       <div className="BillTitle">
                        <span>Manee Cafe</span>
                        </div>
                    }

                    {
                       billDetails.LocationName ==="BIC" && 
                       <div className="BillTitle">
                            <span>BIC Coffee</span>
                       </div>
                    }

{
                       billDetails.LocationName ==="BIC" && 
                       <div className="BillTitle">
                            <span>By Manee Cafe</span>
                       </div>
                    }
                    
                    <div className="BillNO">
                        <span>TableNO. # {billDetails.TableNo}</span>
                    </div>
                    <div className="BillNO">
                        <span>BillNO # {billDetails.BillNO}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>

                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="LocationName">
                        <span>Location: {billDetails.LocationName}</span>
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                        <span className="CountNumber">NO.</span>
                        <span className="ProductName">Price</span>
                        <span className="Qty">Qty</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"></div>
                        <div className="footer-line"></div>
                    </div>
                    <div className="BodyDetails">
                        {
                            productList && productList.map((x, y) => {
                                return (
                                    <div className="Items" key={y}>
                                        <div className="Name">
                                            <span className="ProductName">{x.EngName}</span>
                                        </div>
                                        <div className="Detail">
                                            <div className="mds">
                                                <span className="NO">{(y + 1) }</span>
                                                <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                                <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                            </div>
                                        </div>
                                        <div className="footer-line"></div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <div className="BillFooter">
                    <div className="footer-line"></div>
                    <div className="subtotal">
                        <span>SubTotal:</span>
                        <strong>{currencyFormat(billDetails.SubTotal)}</strong>
                    </div>
                    <div className="discout">
                        <span>Discount:</span>
                        <strong>{currencyFormat(billDetails.DiscountAmount)}</strong>
                    </div>
                    <div className="nettotal">
                        <span>NetTotal:</span>
                        <strong>{currencyFormat(billDetails.NetTotal)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                    <div className="paymentType">
                        <span>PaymentType:</span>
                        <strong>{billDetails.PaymentType}</strong>
                    </div>
                    <div className="paidAmount">
                        <span>PaidAmount:</span>
                        <strong>{currencyFormat(billDetails.PaidAmount)}</strong>
                    </div>
                    {
                        billDetails.PaidMoreAmount > 0 && 
                        <div className="PaidMoreAmount">
                            <span>PaidMoreAmount:</span>
                            <strong>{currencyFormat(billDetails.PaidMoreAmount)}</strong>
                        </div>
                        
                    }

                    {
                        billDetails.MemberNO && 
                        <div className="MemberDetails">
                            <div className="changeAmount">
                                <span>Member:</span>
                                <strong>{billDetails.MemberNO}</strong>
                            </div>
                            <div className="changeAmount">
                                <span>Balance:</span>
                                <strong>{currencyFormat(billDetails.MemberAmount)}</strong>
                            </div>
                            <div className="changeAmount">
                                <span>New Balance:</span>
                                <strong>{currencyFormat(billDetails.MemberNewAmount )}</strong>
                            </div>
                            <div className="changeAmount">
                                <span>Point:</span>
                                <strong>{currencyFormat(billDetails.MemberPoint )}</strong>
                            </div>
                            <div className="changeAmount">
                                <span>New Point:</span>
                                <strong>{currencyFormat(billDetails.MemberNewPoint )}</strong>
                            </div>
                        </div>
                    }
                    {
                       billDetails.NetTotal > billDetails.MemberAmount &&
                       <div className="changeAmount">
                        <span>Change:</span>
                        <strong>{currencyFormat(billDetails.ChangeAmount)}</strong>
                       </div>
                    }
                    
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                    <div className="TaxBeforeVat">
                        <span>Total Before VAT:</span>
                        <strong>{currencyFormat((Number(billDetails.NetTotal) - Number(billDetails.Tax) ))}</strong>
                    </div>
                    <div className="VAT">
                        <span>VAT(10%):</span>
                        <strong>{currencyFormat(billDetails.Tax)}</strong>
                    </div>
                    <div className="TotalTax">
                        <span>Total:</span>
                        <strong>{currencyFormat(billDetails.NetTotal)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="Thankyou">
                        <span>Thank you!</span>
                    </div>
                </div>
            </div>
        );
    }
}

function Payment(props) {

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
    }

    const {tableNumber, TableID, setShowAlert, setErrorMessage, TempOrder, setTempOrder, PaymentTypeList, DiscountList, 
        showPayment, setShowPayment, CurrencyList, subTotal, setSubTotal, NetTotal, setNetTotal, setTableList, setShowOrder, Tax, setTax} = useContext(POSContent);
    const {userID, LocationID, UserName, LocationName} = useContext(UserContext);
    const componentRef = useRef();

    const [TransactionID, setTransactionID] = useState("");
    const [PaymentTypeID, setPaymentTypeID] = useState();
    const [PaymentType, setPaymentType] = useState("");

    const [PaidAmount, setPaidAmount] = useState();
    const [PaidMoreAmount, setPaidMoreAmount] = useState();
    const [DiscountAmount, setDiscountAmount] = useState();
    const [ChangeAmount, setChangeAmount] = useState(0);
    const [DiscountPercent, setDiscountPercent] = useState(0);
    const [DiscountType, setDiscountType] = useState(0);
    const [disblebtnpayment, setDisblebtnpayment] = useState(1);

    
    const [MemberID, setMemberID] = useState("");
    const [MemberNO, setMemberNO] = useState("");
    const [MemberName, setMemberName] = useState("0");
    const [MemberNewAmount, setMemberNewAmount] = useState(0);
    const [MemberAmount, setMemberAmount] = useState(0);
    const [MemberPoint, setMemberPoint] = useState(0);
    const [MemberNewPoint, setMemberNewPoint] = useState(0);

    const [GiftCardID, setGiftCardID] = useState("");
    const [GiftCardNO, setGiftCardNO] = useState("");
    const [GiftCardAmount, setGiftCardAmount] = useState(0);

    const [RewardCardID, setRewardCardID] = useState("");
    const [RewardCardNO, setRewardCardNO] = useState("");
    const [RewardName, setRewardName] = useState("0");
    const [RewardNewPoint, setRewardNewPoint] = useState(0);
    const [RewardPoint, setRewardPoint] = useState(0);

    const [TotalPaidAmount, setTotalPaidAmount] = useState(0);
    const [PaidAmountByCurrency, setPaidAmountByCurrency] = useState(0);

    const [InputClick, setInputClick] = useState(0);

    function currencyFormat(num) {
        if (num !== "") {
            if(num >= 0 || num <= 0){
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        } 
    }

    const CalculateChangeAmount = (paidAmount, PaidMoreAmount, DiscountAmount, memberAmount) => {
        if (subTotal > 0) {
            let gPaidAmount = 0;
            let gPaidMoreAmount = 0;
            let gDiscountAmount = 0;
            if(paidAmount > 0){
                gPaidAmount = paidAmount;
            }
            if(PaidMoreAmount > 0){
                gPaidMoreAmount = PaidMoreAmount;
            } 
            if(DiscountAmount > 0)
            {
                gDiscountAmount=DiscountAmount;
            }
            var change = (Number(gPaidAmount) + Number(gPaidMoreAmount) + Number(gDiscountAmount)) - (Number(subTotal));
            if(PaymentType == "Member"){
                if(memberAmount >= (Number(subTotal) - Number(gDiscountAmount)))
                    setMemberNewAmount((Number(memberAmount) + Number(gDiscountAmount)) - (Number(subTotal)));
                else
                    setMemberNewAmount(0);
            } else if(PaymentType == "Gift Card"){
                change = 0;
            }
            var totalpaidAmount = (Number(gPaidAmount) + Number(gPaidMoreAmount));
            setTotalPaidAmount(totalpaidAmount);
            setChangeAmount(change);
        }
    }

    const CalculateDiscount = (discountPercent) => {
        var gDiscountAmount = 0;
        var NewNetTotal = subTotal;
        var gIscount = (Number(subTotal) * (Number(discountPercent) / Number(100))).toFixed(0);
        var roundUp = gIscount.toString().substr(-3, 3);
        var tax = 0;
        if(roundUp > 500){
            gDiscountAmount = (Number(gIscount) - Number(roundUp)) + 1000;
            NewNetTotal = Number(subTotal) - Number(gDiscountAmount);
        } else if(roundUp < 500) {
            gDiscountAmount = (Number(gIscount) - Number(roundUp));
            NewNetTotal = Number(subTotal) - Number(gDiscountAmount);
        } else {
            gDiscountAmount = gIscount;
            NewNetTotal = Number(subTotal) - Number(gIscount);
        }
        tax = (Number(NewNetTotal) - ((Number(NewNetTotal) * 100) /110)).toFixed(2);
        setTax(tax);
        setDiscountAmount(gDiscountAmount);
        setNetTotal(NewNetTotal);
        CalculateChangeAmount(PaidAmount, PaidMoreAmount, gDiscountAmount, MemberAmount);
    }

    const handleInputChange = event => {
        const {value } = event.target;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                setPaidAmount(value.split(',').join(''));
                CalculateChangeAmount(value.split(',').join(''), PaidMoreAmount, DiscountAmount, MemberNewAmount);
            }  
        } else {
            setPaidAmount();
            CalculateChangeAmount(0, PaidMoreAmount, DiscountAmount, MemberNewAmount);
        }
    };

    const handleInputClick = (e) => {
        setInputClick(e);
    };

    const hanldeclickMoney = (money) => {
        if(!InputClick & PaymentType != "Member" & PaymentType != "MEMBER"){
            if(PaidAmount > 0){
                var totalpaidAmount = Number(PaidAmount) + Number(money);
                setPaidAmount(totalpaidAmount);
                CalculateChangeAmount(totalpaidAmount, PaidMoreAmount, DiscountAmount, MemberAmount);
            } else{
                setPaidAmount(money);
                CalculateChangeAmount(money, PaidMoreAmount, DiscountAmount, MemberAmount);
            }
        } else {
            if(PaidMoreAmount > 0){
                var totalpaidMoreAmount =Number(PaidMoreAmount) + Number(money);
                setPaidMoreAmount(totalpaidMoreAmount);
                CalculateChangeAmount(PaidAmount, totalpaidMoreAmount, DiscountAmount, MemberAmount);
            } else {
                setPaidMoreAmount(money);
                CalculateChangeAmount(PaidAmount, money, DiscountAmount, MemberAmount);
            }
        }
    }

    const handleChangeDiscount = (event) => {
        const { value } = event.target;
        var tax = 0;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                var discount = Number(value.split(',').join(''))
                var NewNetTotal = Number(subTotal) - Number(discount);
                tax = (Number(subTotal) - ((Number(subTotal) * 100) /110)).toFixed(2);
                setDiscountAmount(discount);
                setNetTotal(NewNetTotal);
                setTax(tax);
                CalculateChangeAmount(PaidAmount, PaidMoreAmount, discount, MemberAmount)
            }  
        } 
        else {
            tax = ((Number(subTotal) - (Number(subTotal) * 100) /107)).toFixed(2);
            setDiscountAmount(0);
            setNetTotal(subTotal);
            setTax(tax);
            CalculateChangeAmount(PaidAmount, PaidMoreAmount, 0, MemberAmount)
        }
    }

    const handleInputMoreCashChange = event => {
        const { value } = event.target;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                setPaidMoreAmount(value.split(',').join(''));
                CalculateChangeAmount(PaidAmount, value.split(',').join(''), DiscountAmount, MemberAmount);
            }  
        } else {
            setPaidMoreAmount();
            CalculateChangeAmount(PaidAmount, 0, DiscountAmount, MemberAmount);
        }
    };

    const handleSelectDiscountType = (e) => {
        var data = DiscountList.filter(function (item) {
            return item.DiscountType == e.target.value
        })
        if (data.length > 0) {
            setDiscountPercent(data[0].Discount);
            CalculateDiscount(data[0].Discount);
            setDiscountType(data[0].DiscountType);
        } else {
            setDiscountPercent("");
            setDiscountType("");
            CalculateDiscount(0);
            setNetTotal(subTotal);
            CalculateChangeAmount(PaidAmount, PaidMoreAmount, 0, MemberAmount)
        }
    };

    const handleSelectPaymentType = (e) => {
        var data = PaymentTypeList.filter(function (item) {
            return item.PaymentType === e.target.value
        })
        if (data.length > 0) {
            if(data[0].PaymentType == "Cash" || data[0].PaymentType == "CASH"){
                setPaidMoreAmount();
                CalculateChangeAmount(PaidAmount, 0, DiscountAmount, MemberAmount);
            } else if(data[0].PaymentType == "Member" || data[0].PaymentType == "Gift Card") {
                setPaidAmount();
                CalculateChangeAmount(0, PaidMoreAmount, DiscountAmount, MemberAmount);
            }
            setPaymentTypeID(data[0].PaymentTypeID);
            setPaymentType(data[0].PaymentType);
        } else {
            setPaymentTypeID("");
            setPaymentType("");
        }
    };

    const CalculatePoint = (Point) => {
        if(NetTotal >= 20000){
            if(PaymentType == "Member" || PaymentType == "MEMBER") {
                var gPoint = ((Number(NetTotal) / Number(20000)) * 1).toFixed(0);
                setMemberNewPoint(Number(gPoint) + Number(Point));
                setRewardNewPoint(0);
            } else {
                var gPoint = (Number(NetTotal) / Number(20000)).toFixed(0);
                setRewardNewPoint(Number(gPoint) + Number(Point));
                setMemberNewPoint(0);
            }
        }
    }

    async function handleSearchMembers(event) {
        const { name, value } = event.target;
        setMemberNO(value);
        let token = localStorage.getItem("token");
        var data = {
            MemberNO: value
        }
        setMemberID("");
        setGiftCardID("");
        setRewardCardID("");
        await axios.post("/searchMember", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let memberData = response?.data;
            if(memberData.length > 0){
                setPaidAmount(memberData[0].Amount);
                setMemberID(memberData[0].MemberID);
                setMemberName(memberData[0].FirstName + ' ' + memberData[0].LastName);
                setMemberAmount(memberData[0].Amount);
                setMemberPoint(memberData[0].Point);
                CalculateChangeAmount(memberData[0].Amount, PaidMoreAmount, DiscountAmount, memberData[0].Amount);
                CalculatePoint(memberData[0].Point)
            } else {
                setPaidAmount();
                setMemberID("");
                setMemberName("");
                setMemberAmount(0);
                CalculateChangeAmount(0, PaidMoreAmount, DiscountAmount, 0);
                setMemberPoint(0);
                setMemberNewPoint(0);
            }
    
        }).catch((err) => {
            console.log(err);
        });
    };

    async function handleSearchGiftCard(event) {
        const { name, value } = event.target;
        setGiftCardNO(value);
        let token = localStorage.getItem("token");
        var data = {
            GiftCardNO: value,
        }
        setMemberID("");
        setGiftCardID("");
        setRewardCardID("");
        await axios.post("/searchGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let memberData = response?.data;
            if(memberData.length > 0){
                setGiftCardID(memberData[0].GiftCardID);
                setGiftCardAmount(memberData[0].Price);
                setPaidAmount(memberData[0].Price);
                CalculateChangeAmount(memberData[0].Price, PaidMoreAmount, DiscountAmount, MemberAmount);
            } else {
                setGiftCardID(0);
                setGiftCardAmount(0);
                setPaidAmount();
                CalculateChangeAmount(0, PaidMoreAmount, DiscountAmount, MemberAmount);
            }
    
        }).catch((err) => {
            console.log(err);
            setGiftCardID(0);
            setGiftCardAmount(0);
            setPaidAmount();
            CalculateChangeAmount(0, PaidMoreAmount, DiscountAmount, MemberAmount);
        });
    };

    async function handleSearchRewardCard(event) {
        const { name, value } = event.target;
        setRewardCardNO(value);
        setMemberID("");
        setGiftCardID("");
        setRewardCardID("");
        let token = localStorage.getItem("token");
        await axios.get(`/searchRewardCard/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let memberData = response?.data;
            if(memberData.length > 0){
                setRewardCardID(memberData[0].RewardCardID);
                setRewardName(memberData[0].FirstName + ' ' + memberData[0].LastName);
                setRewardPoint(memberData[0].Point);
                CalculatePoint(memberData[0].Point);
            } else {
                setRewardCardID("");
                setRewardName("");
                setRewardPoint(0);
                setRewardNewPoint(0);
            }
        }).catch((err) => {
            console.log(err);
            setRewardCardID("");
            setRewardName("");
            setRewardPoint(0);
            setRewardNewPoint(0);
        });
    };

    async function saveTutorial() {
        if(!PaymentTypeID){
            setErrorMessage("ກະລຸນາເລືອກປະເພດຈ່າຍ");
            setShowAlert(true);
        } else {
            let gPaidAmount = 0;
            let gPaidMoreAmount = 0;
            if(PaidAmount > 0){
                gPaidAmount = PaidAmount;
            }
            if(PaidMoreAmount > 0){
                gPaidMoreAmount = PaidMoreAmount;
            } 
            if((Number(gPaidAmount) + Number(gPaidMoreAmount)) >= NetTotal){
                setDisblebtnpayment("")
                CreateTransaction();
            } else {
                setErrorMessage("ຍອດຈ່າຍບໍ່ພຽງພໍ");
                setShowAlert(true);
            }
        }
    };

    async function CreateTransaction(){
        let token = localStorage.getItem("token");
            let gPaidMoreAmount = 0;
            let gDiscountAmount = 0;
            if(PaidMoreAmount > 0){
                gPaidMoreAmount = PaidMoreAmount;
            } 
            if(DiscountAmount > 0)
            {
                gDiscountAmount=DiscountAmount;
            }
        var data = {
            SubTotal: subTotal,
            GiftCardID: GiftCardID,
            MemberID: MemberID,
            RewardCardID: RewardCardID,
            Discount: gDiscountAmount,
            NetTotal: NetTotal,
            PaidAmount: Number(TotalPaidAmount),
            PaidMoreAmount: gPaidMoreAmount,
            ChangeAmount: ChangeAmount,
            PaymentTypeID: PaymentTypeID,
            LocationID: LocationID,
            Tax: Number(Tax),
            CreatedByID: userID,
            CreatedDate:  getDate(),
            StatusID: 1,
        }
        await axios.post("/createTransaction", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            setTransactionID(response?.data?.insertId)
            if(PaymentType == "Member" || PaymentType == "MEMBER"){
                UpdateMember();
            } else if (PaymentType == "Gift Card" || PaymentType == "GIFT CARD"){
                UpdateGiftCard();
            } else if (RewardCardID > 0) {
                UpdateRewardCard();
            } 
            handlePrint();
            CreateTransactionDetail(response?.data?.insertId);
            UpdateTemOrder();
            UpdateTable();
            ClearData();
        }).catch((err) => {
            console.log(err);
        });
    }

    async function UpdateMember() {
        let token = localStorage.getItem("token");
        var data = {
            MemberID: MemberID,
            Amount: MemberNewAmount,
            Point: MemberNewPoint,
        }
        await axios.post("/usemember", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    };

    async function UpdateGiftCard() {
        let token = localStorage.getItem("token");
        var data = {
            GiftCardID: GiftCardID ,
            StatusID: 2,
        }
        await axios.post("/useGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    };

    async function UpdateRewardCard() {
        let token = localStorage.getItem("token");
        var data = {
            RewardCardID: RewardCardID,
            Point: RewardNewPoint,
        }
        await axios.post("/useRewardCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    };

    async function CreateTransactionDetail(TransactionID) {
        let token = localStorage.getItem("token");
        if(TempOrder.length > 0) {
            for (var i=0; i < TempOrder.length; i++) {
                var list;
                list ={
                    TransactionID: TransactionID,
                    ProductID: TempOrder[i].ProductID,
                    Quantity: TempOrder[i].Quantity,
                    Retail_Price: TempOrder[i].Retail_Price,
                    SubTotal: TempOrder[i].SubTotal,
                };
                await axios.post("/createTransactionDetail", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                    getStoreInventory(TempOrder[i].ProductID, TempOrder[i].Quantity)
                }).catch((err) => {
                    console.log(err);
                });
            } 
            // ClearData();
        }
    };

    async function getStoreInventory (ProductID, Quantity) {
        let token = localStorage.getItem("token");
        var data = {
            ProductID: ProductID,
            LocationID: LocationID,
        }
        await axios.post("/getStoreInventory",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let inventoryData = response?.data;
            var gQuantity = 0;
            if(inventoryData.length > 0){
                gQuantity = Number(inventoryData[0].Quantity) - Number(Quantity);
                dropInventory(inventoryData[0].StoreInventoryID, gQuantity);
            } 
        }).catch((err) => {
            console.log(err);
        });
    }
    
    async function dropInventory (StoreInventoryID, Quantity) {
        let token = localStorage.getItem("token");
        var data = {
            StoreInventoryID: StoreInventoryID,
            Quantity: Quantity,
        }
        await axios.post("/PosUpdateStoreInventory", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    async function UpdateTable() {
        let token = localStorage.getItem("token");

        await axios.put(`/UpdateTableForPos/${TableID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllTableNumber();
        }).catch((err) => {
            console.log(err);
        });
    };

    async function UpdateTemOrder () {
        let token = localStorage.getItem("token");
        var data = {
            TableID: TableID,
            LocationID: LocationID,
        }
        await axios.post("/UpdateTemOrderPayment", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    async function getAllTableNumber() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAlltableNumberForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setTableList(temOrder);
          } else {
            setTableList([]);
          }
        }).catch((err) => {
            setTableList([]);
            console.log(err);
        });
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const ClearData = () => {
        const timeout = setTimeout(() => {
            setTransactionID("");

            setPaidAmount();
            setPaidMoreAmount();
            setDiscountAmount();
            setChangeAmount(0);
            
            setMemberID("");
            setMemberNO("");
            setMemberName("0");
            setMemberNewAmount(0);
            setMemberAmount(0);
            setMemberPoint(0);
            setMemberNewPoint(0);

            setGiftCardID("");
            setGiftCardNO("");
            setGiftCardAmount(0);

            setRewardCardID("");
            setRewardCardNO("");
            setRewardName("0");
            setRewardNewPoint(0);
            setRewardPoint(0);

            setTotalPaidAmount(0);
            setPaidAmountByCurrency(0);
            setPaymentType("");
            setPaymentTypeID("");
            setTempOrder([]);
            setSubTotal(0);
            setNetTotal(0);
            setTax(0);
            setShowPayment(false);
            setShowOrder(false);
            setDisblebtnpayment(1);
            localStorage.removeItem(TableID);
        }, 3000);
        return () => clearTimeout(timeout);
    }

    const onClickClose = () => {
        setTransactionID("");

        setPaidAmount();
        setPaidMoreAmount();
        setDiscountAmount();
        setChangeAmount(0);
        
        setMemberID("");
        setMemberNO("");
        setMemberName("0");
        setMemberNewAmount(0);
        setMemberAmount(0);
        setMemberPoint(0);
        setMemberNewPoint(0);

        setGiftCardID("");
        setGiftCardNO("");
        setGiftCardAmount(0);
        setPaymentType("");
        setPaymentTypeID("");
        setRewardCardID("");
        setRewardCardNO("");
        setRewardName("0");
        setRewardNewPoint(0);
        setRewardPoint(0);

        setTotalPaidAmount(0);
        setPaidAmountByCurrency(0);
        setShowPayment(false);
    }

    return (
        <div style={{ display: showPayment ? 'flex' : 'none' }} className="paymentForm">
            <div className="PaymentContents">
                <div className="leftConten">
                    <div className="Header">
                        <span>ເລກໂຕະ# {tableNumber}</span>
                    </div>
                    <div className="inputAmout">
                        <div className="selectContent"> 
                            <div className="PaymentType">
                                <label>ປະເພດຈ່າຍ</label>
                                <select className="PaymentType" onChange={(e) => handleSelectPaymentType(e)}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດຈ່າຍ'}
                                    </option>,
                                    {PaymentTypeList && PaymentTypeList.map(item => (
                                        <option
                                            key={item.PaymentTypeID}
                                            value={item.PaymentType}
                                        >
                                            {item.PaymentType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="DiscountType">
                                <label>ສ່ວນຫລຸດ</label>
                                <select className="DiscountType" onChange={(e) => handleSelectDiscountType(e)}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກສ່ວນຫລຸດ'}
                                    </option>,
                                    {
                                        DiscountList && DiscountList.map(item => (
                                        <option
                                            key={item.Discount}
                                            value={item.DiscountType}
                                        >
                                            {item.DiscountType}
                                        </option>
                                    ))}
                                </select>
                                {/* {errors.DiscountType && (
                                    <p className="invalid-feedback">{errors.DiscountType}</p>
                                )} */}
                            </div>
                        </div>
                        <div className="inputContent">
                            <div className="txtPaidAmount">
                                <label>ຍອດຈ່າຍ</label>
                                <input className={`input form-control`} name="PaidAmount" placeholder="ຈ່າຍ" required onClick={(e) => handleInputClick(0)} onChange={(e) => handleInputChange(e)} 
                                value={currencyFormat(PaidAmount)} disabled={PaymentType === "Gift Card" || PaymentType === "Member" || PaymentType === ""}  />
                                {/* {errors.PaidAmount && (
                                    <p className="invalid-feedback">{errors.PaidAmount}</p>
                                )} */}
                            </div>
                            <div className="txtPaidMore">
                                <label>ຈ່າຍເພີ່ມ</label>
                                <input className={`input form-control`} name="PaidMordAmount" placeholder="ຈ່າຍເພີ່ມເງິນສົດ" onClick={(e) =>handleInputClick(1)} required onChange={(e) => handleInputMoreCashChange(e)} 
                                value={currencyFormat(PaidMoreAmount)} disabled={!PaymentType || PaymentType === "Cash" || PaymentType === "CASH"} />
                                {/* {errors.PaidMoreAmount && (
                                    <p className="invalid-feedback">{errors.PaidMoreAmount}</p>
                                )} */}
                            </div>
                        </div>
                    </div>
                        <div className="inputMoneyContent">
                            <div className="group1">
                                <div className="money" onClick={(e) => hanldeclickMoney(500)}>
                                    <img src={kip500} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(1000)}>
                                    <img src={kip1000} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(2000)}>
                                    <img src={kip2000} />
                                </div>
                            </div>
                            <div className="group2">
                                <div className="money" onClick={(e) => hanldeclickMoney(5000)}>
                                    <img src={kip5000} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(10000)}>
                                    <img src={kip10000} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(20000)}>
                                    <img src={kip20000} />
                                </div>
                            </div>
                            <div className="group3">
                                <div className="money" onClick={(e) => hanldeclickMoney(50000)}>
                                    <img src={kip50000} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(100000)}>
                                    <img src={kip100000} />
                                </div>
                            </div>
                        </div>
                    <div className="footer">

                    </div>
                </div>
                <div className="rightContent">
                    <div className="Header">
                        <div className="btnclose" onClick={e => onClickClose()}>X</div>
                    </div>
                    <div className="body">
                        <div className="subtotal">
                            <span>ຍອດລວມ:</span>
                            <strong>{currencyFormat(subTotal)}</strong>
                        </div>
                        <div className="discout">
                            <span>ສ່ວນຫລຸດ:</span>
                            <strong>{<input className={`input form-control`} name="Discount" placeholder="0" required onChange={(e) => handleChangeDiscount(e)} 
                                value={currencyFormat(DiscountAmount)} />}</strong> 
                        </div>
                        <div className="tax">
                            <span>Tax:</span>
                            <strong>{currencyFormat(Tax) || '0'}</strong>
                        </div>
                        <div className="nettotal">
                            <span>ຍອດລວມຕ້ອງຈ່າຍ:</span>
                            <strong>{currencyFormat(NetTotal) || '0'}</strong>
                        </div>
                        <div className="footer-line"></div>
                        <div className="paidAmount">
                            <span>ຍອດຈ່າຍ:</span>
                            <strong>{currencyFormat(PaidAmount) || '0'}</strong>
                        </div>
                        <div className="changeAmount">
                            <span>ເງິນທອນ:</span>
                            <strong>{currencyFormat(ChangeAmount) || '0'}</strong>
                        </div>
                        
                        { PaymentType == 'Member' && 
                            <div className="MemerCard">
                                <div className="CardNumber">
                                    <label>ເລກບັດ Member</label>
                                    <input className={`input form-control`} type="text" name="MemberNO"  onChange={(e) => handleSearchMembers(e)} value={MemberNO || ''} required />
                                </div>
                                
                                <div className="Name">
                                    <label>ຊື່ </label>
                                    <span>{MemberName}</span>
                                </div>
                                <div className="Balance">
                                    <label>Balance</label>
                                    <span>{MemberAmount}</span>
                                </div>
                                <div className="NewBalance">
                                    <label>New Balance:</label>
                                    <span>{MemberNewAmount}</span>
                                </div>
                            </div>
                        }

                        { LocationName !="BIC" && PaymentType !== 'Member' && PaymentType !== 'Gift Card' && 
                            <div className="MemerCard">
                                <div className="CardNumber">
                                    <label>ເລກບັດ Reward</label>
                                    <input className={`input form-control`} type="text" name="RewardNO"  onChange={(e) => handleSearchRewardCard(e)} value={RewardCardNO || ''} required />
                                </div>
                                
                                <div className="Name">
                                    <label>ຊື່ </label>
                                    <span>{RewardName}</span>
                                </div>
                                <div className="Balance">
                                    <label>Point</label>
                                    <span>{RewardPoint}</span>
                                </div>
                                <div className="NewBalance">
                                    <label>New Point:</label>
                                    <span>{RewardNewPoint}</span>
                                </div>
                            </div>
                        }
                        { LocationName !="BIC" && PaymentType == 'Gift Card' && 
                            <div className="MemerCard">
                                <div className="CardNumber">
                                    <label>ເລກບັດ Gift Card</label>
                                    <input className={`input form-control`} type="text" name="GiftCardNO"  onChange={(e) => handleSearchGiftCard(e)} value={GiftCardNO || ''} required />
                                </div>
                                <div className="NewBalance">
                                    <label>ລາຄາບັດ:</label>
                                    <span>{GiftCardAmount}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="footer">
                        <div className="btn-Payment">
                            <button className="btn" onClick={(e) => saveTutorial()} disabled={!disblebtnpayment}>ຈ່າຍເງິນ</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'none'}}>
            <ComponentToPrint ref={componentRef}
                    TemProductList={TempOrder}
                    BillDetails={{"Queue": "testQueue", "BillNO": TransactionID, "TableNo": tableNumber, "cashier": UserName, 
                    "LocationName": LocationName, "DiscountType": DiscountType,
                    "GiftCardNO": GiftCardNO, "GiftPrice": GiftCardAmount, 
                    "RewardName": RewardName, "RewardNO": RewardCardNO, "MemberPoint": MemberPoint,"MemberNO": MemberNO, 
                    "MemberAmount": MemberAmount, "MemberNewAmount": MemberNewAmount, "MemberNewPoint": MemberNewPoint,
                    "MemberName": MemberName, "PaymentType": PaymentType, "SubTotal": subTotal, "DiscountAmount": DiscountAmount, 
                    "Tax": Tax, "NetTotal": NetTotal, "PaidAmount": PaidAmount,"PaidMoreAmount": PaidMoreAmount, "ChangeAmount": ChangeAmount}} 
                />
            </div>
        </div >
    );
};

export default Payment;
